import React from "react";
import { Link, navigate } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";
import ContentBox from "../components/contentbox";
import Faq from "../components/faq";
import Team from "../components/team";

const IndexPage = () => (
  <Layout>
    <SEO title="Für Ärzte" keywords={[`gatsby`, `application`, `react`]} />
    <Hero
      image={require("../images/cover-aerzte.jpg")}
      title="Ihr Partner für Wundbehandlung"
      desc="Das WKM Rösner ist ein modernes medizinisches Zentrum für die Versorgung chronischer Wunden. Wir bieten in Mönchengladbach und Willich die bestmögliche Wundbehandlung auf zertifiziertem und standardisiertem Qualitätsniveau."
      to={"/kontakt"}
    />
    <ContentBox
      image={require("../images/shutterstock_221398282.jpg")}
      title="Qualifizierte Wundversorgung"
      desc={`Im Fokus steht zunächst eine zeitnahe und deutliche Verbesserung der Beschwerden der Betroffenen, gefolgt von einer dauerhaften Abheilung der Wunde. Ein wichtiger Schritt ist die vertrauensvolle und professionelle  Zusammenarbeit mit Ihnen als behandelnder Arzt – nur so kann Wundmanagement erfolgreich sein. <br /><br />In Absprache mit Ihnen versorgt unser Team professionell und sorgsam die Wunden nach neuesten wissenschaftlichen Erkenntnissen.<br /><br />Bei Bedarf kümmern wir uns um die Entlastung, Ernährung und um die medizinische Hautpflege. Wir kümmern uns auch um Patienten im häuslichen Bereich.`}
    />
    <ContentBox
      image={require("../images/clouddoku.jpg")}
      title="Wunddokumentation"
      desc={`Wir haben Wunddokumentation neu gedacht und eine App entwickelt, 
      die bei möglichst einfacher Bedienung die professionellste Art der Wunddokumentation darstellt – einfach, sicher und schnell.<br /><br />
      Sie als behandlender Arzt haben jederzeit die Möglichkeit, die Dokumentation Ihrer Patienten mit aktuellen Bildern, Wundverläufen, Therapieempfehlungen einzusehen, ohne dass wir Daten verschicken. So sind Sie immer über den aktuellen Zustand informiert. Darüber hinaus besteht die einzigartige Option der zeitgleichen Dokumentation und Kommunikation.`}
      actionLabel="www.clouddoku.de"
      imageFirst
    />
    <ContentBox
      image={require("../images/lllt.jpg")}
      title="LLLT Low-Lever-Laser-Therapie"
      desc={`Die LLLT ist als komplementäre, zusätzliche Therapie zur unserer leitliniengerechten Wundbehandlung sinnvoll. LLLT beschleunigt die Wundheilung, vermindert das Infektionsrisiko, ist durchblutungsfördernd und schmerzlindernd, entzündungshemmend und abschwellend. Die LLLT wird bereits seit Jahren im WKM Rösner eingesetzt.`}
    />
    <Team />
    <Faq />
  </Layout>
);

export default IndexPage;
